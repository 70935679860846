/* ---------- Fonts ---------- */
/* Montserrat */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* Nunito Sans */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
/* Cookie */
@import url("https://fonts.googleapis.com/css2?family=Cookie&display=swap");
/* Bad Script */
@import url("https://fonts.googleapis.com/css2?family=Bad+Script&family=Cookie&family=Sacramento&display=swap");

/* ---------- Font Sizes ---------- */
:root {
  --xx-small: 12px;
  --x-small: 16px;
  --small: 18px;
  --medium: 24px;
  --large: 36px;
  --x-large: 48px;
  --xx-large: 56px;
}

/* ---------- Colors ---------- */
:root {
  --light-pink: #f7e5e1;
  --pink: #f9b7b6;
  --medium-pink: #ff8785;
  --shade-pink: #ff5a57;
  --rosey: rgb(255, 134, 180);
  --navy: #073f74;

  --whitesmoke: whitesmoke;
  --light-gray: #e9e9e7;
  --gray: #9b9b9b;
  --medium-gray: #545454;
  --dark-gray: #333;
}

/* ---------- Headings ---------- */
h1 {
  margin: 0;
  font-size: var(--xx-large);
  font-family: Nunito Sans, Montserrat, Arial;
  font-weight: bold;
}

h2 {
  margin: 0;
  font-size: var(--x-large);
}

h3 {
  margin: 0;
  font-size: var(--large);
}

h4 {
  margin: 0;
  font-size: var(--medium);
  color: var(--medium-gray);
}

h5 {
  margin: 0;
  font-size: var(--small);
}

h6 {
  margin: 0;
  font-size: var(--x-small);
}

h7 {
  margin: 0;
  font-size: 18px;
}

/* ---------- Reuable Elements ---------- */
:root {
  --border-radius: 10px;
  --box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.2);
}

* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background-color: var(--light-pink);
  font-family: Nunito Sans, Montserrat, Arial;
  box-sizing: border-box;
  width: 100vw;
  margin: 0;
}

section {
  max-width: 1400px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.section-heading {
  font-size: var(--large);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: var(--medium-gray);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-heading::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 3px;
  background-color: var(--medium-pink);
  bottom: -6px;
}

p {
  margin: 0;
  font-size: var(--medium);
}

button {
  color: white;
  background-color: var(--medium-pink);
  padding: 18px 40px;
  border-radius: var(--border-radius);
  border: none;
  font-size: var(--small);
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
  transition: all 0.2s ease-in;
}

/* ---------- Navigation Bar ---------- */
nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: 2px solid var(--light-gray);
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 10px 30px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.nav-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-img {
  width: 80px;
  padding: 2px;
}

.nav-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 22px;
}

.selected-nav-section {
  color: var(--medium-pink) !important;
}

.selected-nav-section::before {
  position: absolute;
  content: "";
  width: 26px;
  height: 2px;
  background-color: var(--medium-pink);
  bottom: -5px;
}

.nav-buttons a {
  color: var(--gray);
  font-size: var(--small);
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s;
}

.nav-buttons a:hover {
  color: var(--medium-pink);
}

.nav-buttons a:hover::before {
  position: absolute;
  content: "";
  width: 26px;
  height: 2px;
  background-color: var(--medium-pink);
  bottom: -5px;
  animation-name: nav-hover-line-transition;
  animation-duration: 0.3s;
}

@keyframes nav-hover-line-transition {
  from {
    width: 0px;
  }
  to {
    width: 26px;
  }
}

.nav-buttons a:active {
  color: var(--medium-pink);
}

.social-media-icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.social-media-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  background-color: var(--medium-pink);
  padding: 8px;
  border-radius: 50%;
  transition: all 0.2s ease-in;
}

.social-media-link:hover {
  background-color: var(--shade-pink);
  cursor: pointer;
  transform: scale(1.2);
}

.collapse-icon {
  font-size: 20px;
  background-color: none;
  color: var(--gray);
  margin-left: 30px;
  display: none;
}

.cross-icon {
  display: none;
}

@media screen and (max-width: 768px) {
  .is-mobile-hide {
    display: none;
  }

  .is-mobile-show {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 2;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .is-mobile-show a {
    font-size: 26px;
  }

  .is-mobile-show .cross-icon {
    font-size: 18px;
  }

  .collapse-icon {
    display: block;
    transition: all 0.2s;
  }
  .collapse-icon:hover {
    color: var(--medium-pink);
  }

  .cross-icon {
    display: block;
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .nav-container {
    max-width: 100%;
  }
}

/* ---------- Home ---------- */
.home-section {
  max-width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)),
    url("/public/images/backgrounds/desk-setup-background-4.avif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.overlay {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgb(255, 255, 255, 0.6);
  z-index: 5;
}

.home-container {
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

.home-container h1 {
  color: white;
  letter-spacing: 4px;
  text-align: center;
  font-size: 50px;
}

.home-container h4 {
  color: var(--whitesmoke);
  font-size: var(--medium);
  font-weight: 300;
  margin-bottom: 10px;
  padding: 0px 20px 20px 20px;
}

.about-me-button {
  font-size: 14px;
}

.about-me-button:hover {
  cursor: pointer;
  background-color: var(--shade-pink);
  transform: scale(1.1);
}

@media screen and (max-width: 700px) {
  .home-text h1 {
    font-size: 44px;
  }
  .home-text h4 {
    display: flex;
    flex-wrap: wrap;
    font-size: 22px;
  }
  .about-me-button {
    font-size: 14px;
    padding: 14px 30px;
  }
}

@media screen and (max-width: 575px) {
  .home-text h4 {
    padding-top: 10px;
    padding-left: 60px;
    padding-right: 60px;
    line-height: 32px;
  }
  .about-me-button {
    font-size: 14px;
    padding: 10px 30px;
  }
}

@media screen and (max-width: 400px) {
  .home-text h1 {
    font-size: 40px;
  }
  .home-text h4 {
    font-size: 20px;
    padding-top: 20px;
  }
  .about-me-button {
    font-size: 12px;
  }
}

/* ---------- About Me ---------- */

.about-me-container {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  border-radius: 5px;
}

.about-me-content {
  display: flex;
  flex-direction: row;
  gap: 50px;
  padding: 80px;
}

.about-me-left {
  width: 40%;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  background-color: var(--light-pink);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.about-me-left-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about-me-left-text h4 {
  text-align: center;
  color: var(--medium-gray);
  padding-bottom: 10px;
  border-bottom: 3px solid var(--medium-pink);
}

.about-me-left-text h7 {
  font-size: 18px;
  line-height: 34px;
  color: var(--medium-gray);
  text-align: center;
  width: 300px;
}

.about-me-left-img {
  text-align: center;
}

.about-me-photo {
  width: 60%;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.about-me-right {
  width: 60%;
  border-radius: 10px;
}

/* ACCORDION */
.accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.accordion-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.accordion-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  color: var(--dark-gray);
  background-color: var(--light-pink);
  padding: 10px;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  cursor: pointer;
  position: relative;
  border-bottom: 3px solid #f1f1f1;
  transition: all 0.4s;
}

.accordion-title:hover {
  background-color: var(--medium-pink);
  color: white;
}

.accordion-title.active {
  color: white;
  background-color: var(--medium-pink);
  font-weight: bold;
}

.accordion-summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  padding-bottom: 50px;
  background-color: white;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
  position: relative;
}

.accordion-summary p {
  font-size: 16px;
}

.accordion-summary.hidden {
  display: none;
}

.as-a-developer-list {
  padding-inline-start: 20px;
  margin: 0px;
}

.as-a-developer-list li {
  font-size: 16px;
}

.open-button {
  background-color: var(--medium-pink);
  color: white;
  letter-spacing: normal;
  padding: 10px 20px;
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.accordion-summary button:hover {
  background-color: var(--shade-pink);
}

.accordion-content {
  display: hidden;
  padding: 30px;
  padding-bottom: 60px;
  background-color: white;
  position: relative;
}

.accordion-content p {
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
}

.accordion-content.hidden {
  display: none;
}

.close-button {
  background-color: var(--medium-pink);
  color: white;
  letter-spacing: normal;
  padding: 10px 20px;
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.close-button:hover {
  background-color: var(--shade-pink);
}

.chevron {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}

.chevron.rotate {
  transform: translateY(-50%) rotate(180deg);
}

@media screen and (max-width: 1270px) {
  .about-me-content {
    padding-left: 50px;
    padding-right: 50px;
  }
  .about-me-left {
    width: 50%;
    justify-content: center;
  }
}

@media screen and (max-width: 1230px) {
  .about-me-left {
    height: 600px;
  }
}

@media screen and (max-width: 1100px) {
  .about-me-container {
    height: auto;
  }
  .about-me-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 80px;
  }
  .about-me-left {
    width: 100%;
    flex-direction: row;
    height: auto;
    padding: 30px;
  }
  .about-me-left-img img {
    width: 210px;
  }
  .about-me-right {
    width: 100%;
  }
}

@media screen and (max-width: 950px) {
  .about-me-left {
    padding: 20px;
  }
  .about-me-left-text {
    justify-content: center;
    gap: 10px;
  }
  .about-me-left-text h7 {
    font-size: 18px;
  }
  .about-me-left-img img {
    width: 180px;
  }
  .about-me-content {
    gap: 30px;
  }
}
@media screen and (max-width: 845px) {
  .about-me-content {
    padding: 40px;
  }
  .about-me-left {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .about-me-left-text {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
  }
  .about-me-left-text h4 {
    margin: 0px 40px;
    text-align: center;
    font-size: 20px;
  }
  .about-me-left-text h7 {
    text-align: center;
    font-size: 18px;
    width: 400px;
  }
  .about-me-left-img {
    width: 38%;
  }
}

@media screen and (max-width: 700px) {
  .about-me-container {
    padding: 40px;
    padding-top: 100px;
  }
  .about-me-content {
    padding-bottom: 0px;
  }
  .about-me-left-text {
    width: 400px;
  }
  .about-me-left-img {
    width: 50%;
  }
  .about-me-left-text h7 {
    font-size: 18px;
  }
  .accordion-title {
    font-size: 22px;
  }
  .accordion-summary {
    padding-bottom: 60px;
  }
  .accordion-summary p {
    font-size: 16px;
  }
  .open-button {
    font-size: 12px;
  }
  .accordion-content p {
    font-size: 16px;
  }
  .close-button {
    font-size: 12px;
  }
  .accordion-summary li {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .about-me-content {
    padding: 40px 0px;
  }
  .about-me-left-text {
    width: 80%;
  }
  .about-me-left-text h7 {
    width: 100%;
  }
}

@media screen and (max-width: 535px) {
  .about-me-left-text {
    width: 100%;
  }
  .about-me-left-text h7 {
    width: 90%;
    font-size: 15px;
    line-height: 24px;
  }
}

@media screen and (max-width: 500px) {
  .about-me-left-text h4 {
    font-size: 20px;
    margin: 0px 20px;
  }
}

@media screen and (max-width: 475px) {
  .about-me-left-img {
    width: 100%;
  }
  .about-me-photo {
    width: 80%;
  }
}

/* Skills */
.skills-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
}

.skills-content {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 100px;
  border-radius: 5px;
}

.skills-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.skills-icon {
  width: 75px;
  height: 75px;
  transition: all 0.2s ease-in;
}

.react-icon {
  width: 65px;
  height: 75px;
}

.icon-wrapper:hover img {
  transform: scale(1.1);
}

.icon-caption {
  position: relative;
  bottom: 0px;
  text-align: center;
  visibility: hidden;
  transition: all 0.2s;
}

.icon-wrapper:hover .icon-caption {
  visibility: visible;
}

.icon-caption {
  color: var(--dark-gray);
  font-size: var(--x-small);
  font-weight: bold;
  border-radius: 10px;
  padding: 6px;
}

@media screen and (max-width: 875px) {
  .skills-icons {
    display: flex;
    flex-wrap: wrap;
    padding: 40px;
  }
}

@media screen and (max-width: 770px) {
  .skills-icons {
    padding: 0px 180px;
    justify-content: space-evenly;
    gap: 0px;
  }
}

@media screen and (max-width: 690px) {
  .skills-icons {
    padding: 0px 140px;
  }
}

@media screen and (max-width: 600px) {
  .skills-icons {
    padding: 0px 100px;
  }
}

@media screen and (max-width: 515px) {
  .skills-icons {
    padding: 0px 60px;
  }
  .skills-icon {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 400px) {
  .skills-icons {
    padding: 0px 40px;
  }
  .skills-icon {
    width: 60px;
    height: 60px;
  }
  .skills-content {
    padding: 40px 0px;
  }
}

/* ---------- Portfolio ---------- */
.portfolio-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
}

.portfolio-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  width: 100%;
}

.project-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 2000px;
}

.project-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 23%;
  height: 300px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 1px 12px #f9f9f9;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
  overflow: hidden;
  font-family: Nunito Sans, Montserrat, Arial;
}

.project-card-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.project-card-description-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  padding: 30px 20px;
}

.project-card-description-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
  color: whitesmoke;
  font-size: 14px;
  opacity: 0; /* Initially hide the text */
  transition: opacity 0.3s ease;
}

.project-card-description-content h4 {
  color: white;
  font-size: 26px;
  text-transform: capitalize;
  font-family: "Bad Script", "Cookie", Nunito Sans, cursive;
  font-weight: 600;
}

.project-card:hover .project-card-description-content {
  opacity: 1; /* Show text on hover */
  animation-duration: 0.5s;
  transition: all 0.3s;
}

.project-card-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.project-cards .project-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease; /* Adjust the transition duration if needed */
}

.project-card:hover::before {
  opacity: 1;
}

.live-demo-link,
.source-code-link {
  text-decoration: none;
  color: white;
}

.project-card-1 {
  background-image: url("../public/images/project-SRE-preview.png");
  background-repeat: none;
  background-position: center;
  background-size: cover;
}

.project-card-2 {
  background-image: url("../public/images/project-simply-sweet-by-amy-preview.png");
  background-repeat: none;
  background-position: top;
  background-size: cover;
}

.project-card-3 {
  background-image: url("../public/images/project-ready-set-cook-preview.png");
  background-repeat: none;
  background-position: center;
  background-size: cover;
}

.project-card-4 {
  background-image: url("../public/images/project-hospice-medical-preview.png");
  background-repeat: none;
  background-position: top left;
  background-size: cover;
}

.project-card:hover {
  background-color: var(--light-gray);
}

.project-card:hover button {
  visibility: visible;
}

.project-card:hover .project-card-popup {
  animation-name: delayed-visibility-for-button;
  animation-duration: 0.5s;
  transition: all 0.3s;
}

@keyframes delayed-visibility-for-button {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.project-card-popup {
  position: absolute;
  bottom: 12px;
}

.project-card-popup button {
  padding: 10px;
  margin: 0px 5px;
  border-radius: 4px;
  font-size: var(--xx-small);
  letter-spacing: 1px;
  font-weight: 600;
  cursor: pointer;
  visibility: hidden;
  bottom: 10px;
}

.project-card-popup button:hover {
  background-color: var(--shade-pink);
}

@media screen and (max-width: 1400px) {
  .portfolio-container {
    height: 100%;
    padding-bottom: 100px;
  }
  .portfolio-content {
    height: 100%;
  }
  .project-cards {
    height: 100%;
  }
  .project-card {
    width: 40%;
    height: 320px;
  }
  .project-card-description-content {
    font-size: 18px;
    width: 90%;
  }
  .project-card-description-content h4 {
    font-size: 34px;
  }
}

@media screen and (max-width: 1200px) {
  .portfolio-container {
    height: 100%;
    padding-bottom: 100px;
  }
  .project-cards {
    flex-wrap: wrap;
  }
  .project-card {
    width: 45%;
    height: 300px;
  }
  .project-card-description-content {
    opacity: 1;
    padding-bottom: 0px;
    width: 100%;
  }
  .project-cards .project-card::before {
    opacity: 1;
  }
  .project-card-description-content h4 {
    font-size: 28px;
  }
  .project-card-popup button {
    visibility: visible;
  }
}

@media screen and (max-width: 1000px) {
  .portfolio-container {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .project-card-description-content {
    font-size: 16px;
    gap: 10px;
  }
  .project-card-description-content h4 {
    font-size: 30px;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 805px) {
  .portfolio-container {
    padding: 100px 40px;
    height: 100%;
  }
  .project-cards {
    flex-direction: column;
  }
  .project-card {
    width: 80%;
    height: 320px;
  }
  .project-card-popup button {
    visibility: visible;
  }
  .project-card:hover .project-card-popup {
    animation-name: none; /* Disable animation */
  }
  .project-card-description-content {
    font-size: 18px;
    width: 90%;
    gap: 16px;
  }
  .project-card-description-content h4 {
    font-size: 32px;
  }
}

@media screen and (max-width: 700px) {
  .project-card-description-content {
    font-size: 18px;
    width: 90%;
    gap: 12px;
  }
}

@media screen and (max-width: 600px) {
  .portfolio-container {
    padding: 80px 40px;
  }
  .project-card {
    width: 100%;
    height: 320px;
  }
  .project-card-description-content {
    font-size: 16px;
    width: 90%;
    gap: 16px;
  }
  .project-card-description-content h4 {
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .portfolio-container {
    padding: 80px 20px;
  }
  .project-card {
    width: 100%;
    height: 300px;
  }
  .project-card-popup button {
    padding: 8px;
  }
  .project-card-description-content {
    width: 100%;
  }
  .project-card-description-content h4 {
    font-size: 26px;
  }
}

@media screen and (max-width: 400px) {
  .project-card {
    width: 100%;
    height: 300px;
  }
  .project-card-popup button {
    font-size: 12px;
    margin: 0px 4px;
  }
  .project-card-description-content {
    font-size: 14px;
    width: 100%;
  }
  .project-card-description-content h4 {
    font-size: 24px;
  }
}
/* ---------- Resume ---------- */
.resume-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  background-color: white;
  border-radius: 5px;
}

.resume-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  width: 100%;
}

.resume-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 70px 70px;
  background-color: rgb(249, 183, 182, 0.75);
  border-radius: 5px;
  box-shadow: 1px 1px 12px #cacaca;
}

.resume-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 382.5px;
  height: 495px;
  background-color: white;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
  overflow: hidden;
}

.resume-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease; /* Adjust the transition duration if needed */
}

.resume-card:hover::before {
  opacity: 1;
}

.open-file-link,
.download-file-link {
  text-decoration: none;
  color: white;
}

.resume-card-1 {
  background-image: url("../public/images/amy-dinh-resume-front-page-resize.png");
  background-repeat: none;
  background-position: center;
  background-size: cover;
}

.resume-card-2 {
  background-image: url("../public/images/amy-dinh-resume-work-experience-resize.png");
  background-repeat: none;
  background-position: center;
  background-size: cover;
}

.resume-card:hover {
  background-color: var(--light-gray);
}

.resume-card:hover button {
  visibility: visible;
}

.resume-card:hover .resume-card-popup {
  animation-name: delayed-visibility-for-button;
  animation-duration: 0.5s;
  transition: all 0.3s;
}

.resume-card-popup {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  bottom: 30px;
}

.resume-card-popup button {
  padding: 10px;
  margin: 0px 5px;
  border-radius: 4px;
  font-size: var(--xx-small);
  letter-spacing: 1px;
  font-weight: 600;
  cursor: pointer;
  visibility: hidden;
  bottom: 10px;
}

.resume-card-popup button:hover {
  background-color: var(--shade-pink);
}

@media screen and (max-width: 1200px) {
  .resume-container {
    padding-top: 80px;
    padding-bottom: 50px;
  }
  .resume-container {
    padding-top: 80px;
    padding-bottom: 50px;
  }
  .resume-card-popup button {
    visibility: visible;
  }
}

@media screen and (max-width: 1000px) {
  .resume-container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .resume-card {
    width: 340px;
    height: 440px;
  }
  .resume-cards {
    padding: 40px;
  }
}

@media screen and (max-width: 900px) {
  .resume-container {
    height: auto;
    padding: 80px 0px;
  }
  .resume-cards {
    flex-direction: column;
    padding: 40px 150px;
  }
  .resume-card {
    width: 382.5px;
    height: 495px;
  }
  .resume-content {
    padding-top: 40px;
  }
}

@media screen and (max-width: 700px) {
  .resume-cards {
    padding: 40px 100px;
  }
}

@media screen and (max-width: 600px) {
  .resume-container {
    background-color: var(--pink);
  }
  .resume-cards {
    padding: 0px;
    background: none;
    box-shadow: none;
  }
}

@media screen and (max-width: 420px) {
  .resume-card {
    width: 340px;
    height: 440px;
  }
}

@media screen and (max-width: 350px) {
  .resume-card {
    width: 306px;
    height: 396px;
  }
}

@media screen and (max-width: 310px) {
  .resume-card {
    width: 278px;
    height: 360px;
  }
}

/* ---------- Contact ---------- */
.contact-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 100px;
}

.contact-content {
  display: flex;
  justify-content: center;
  align-items: stretch;
  background-color: #f9f9f9;
  margin-top: 60px;
  padding: 60px;
  border-radius: 10px;
  box-shadow: 1px 2px 12px #f9f9f9;
}

.contact-img-container {
  width: 400px;
  background-image: url("../public/images/send-message-2.avif");
  background-repeat: none;
  background-position: center;
  background-size: cover;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 100%;
  background-color: var(--light-pink);
  font-family: "Raleway", Nunito Sans, Montserrat, Arial;
  font-size: 14px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.contact-elements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  padding: 20px;
  width: 90%;
  height: 100%;
}

.contact-form label {
  color: var(--medium-gray);
  text-transform: uppercase;
  font-family: "Raleway", Nunito Sans, Montserrat, Arial;
  font-size: 16px;
  letter-spacing: 1.5px;
  font-weight: 700;
}

.contact-form input {
  font-size: 14px;
  font-family: "Raleway", Nunito Sans, Montserrat, Arial;
  padding: 10px;
  border: none;
  border-radius: 4px;
}

.contact-form input:focus {
  outline: 2px solid var(--medium-pink);
}

.contact-form textarea {
  padding: 8px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Raleway", Nunito Sans, Montserrat, Arial;
}

.contact-form textarea:focus {
  outline: 2px solid var(--medium-pink);
}

.name-elements {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
  width: 100%;
}

#first-name {
  width: 93%;
}

#last-name {
  width: 93%;
}

.contact-element-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  width: 100%;
}

.contact-element-wrapper input {
  width: 97%;
}

#message {
  width: 97%;
}

#contact-submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  padding: 14px 40px;
  cursor: pointer;
}

#contact-submit-btn:hover {
  background-color: var(--shade-pink);
  transform: scale(1.01);
}

#send-icon {
  font-size: 16px;
}

@media screen and (max-width: 1120px) {
  .contact-content {
    flex-direction: column;
    padding: 30px;
  }
  .contact-container {
    padding: 0px;
    padding-top: 140px;
    padding-bottom: 80px;
  }
  .contact-elements {
    padding: 30px 0px;
  }
  .contact-img-container {
    height: 350px;
    width: 100%;
    background-image: url("../public/images/send-message-2.avif");
    background-repeat: no-repeat;
    background-position-y: -420px;
    background-size: cover;
  }
  .contact-img {
    display: none;
  }
  .submit-btn-container {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1000px) {
  .contact-container {
    padding-top: 80px;
  }
}

@media screen and (max-width: 800px) {
  .contact-container {
    padding-top: 50px;
  }
  .contact-content {
    padding: 40px;
    width: 100%;
  }
  .contact-img-container {
    height: 300px;
    background-position-y: -540px;
  }
  .contact-form {
    padding: 40px 0px;
    width: 100%;
  }
  .contact-elements {
    padding: 0px;
  }
  #contact-submit-btn {
    padding: 12px 30px;
  }
}

@media screen and (max-width: 700px) {
  .contact-container {
    padding-top: 80px;
  }
  .contact-img-container {
    background-position-y: -400px;
  }
  .name-elements {
    flex-direction: column;
    gap: 16px;
  }
  .contact-form {
    padding: 20px 10px;
  }
  #first-name {
    width: 100%;
  }
  #last-name {
    width: 100%;
  }
  .contact-form input {
    width: 100%;
  }
  #message {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .contact-img-container {
    background-position-y: -420px;
  }
  .contact-content {
    padding: 0px;
    box-shadow: none;
  }
  .contact-form {
    padding: 30px;
  }
  .contact-content form {
    padding: 30px 10px;
  }
}

@media screen and (max-width: 500px) {
  .contact-img-container {
    background-position-y: -340px;
  }
}

@media screen and (max-width: 450px) {
  .contact-img-container {
    background-position-y: -290px;
  }
}

@media screen and (max-width: 400px) {
  .contact-img-container {
    background-position-y: -240px;
  }
  .contact-form {
    padding-bottom: 120px;
  }
}

/* ---------- Footer ---------- */
.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  position: relative;
  box-sizing: border-box;
}

.up-arrow-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: var(--medium-pink);
  padding: 20px;
  border-radius: var(--border-radius);
  color: white;
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: -32px;
  right: 50%;
  transform: translateX(50%);
  transition: all 0.3s;
}

.up-arrow-footer:hover {
  background-color: var(--shade-pink);
}

.footer-content-container {
  width: 1050px;
  display: flex;
  padding: 80px;
}

.footer-left {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logo {
  width: 200px;
}

.footer-right {
  width: 75%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.footer-column-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.contact-column {
  display: flex;
  flex-direction: row;
}

.footer-title {
  color: var(--shade-pink);
  padding: 0px 4px 4px 4px;
  border-bottom: 1px solid var(--shade-pink);
  letter-spacing: 2px;
  width: 100%;
}

.footer-title h5 {
  font-size: 14px;
  text-align: center;
}

.navigation-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
}

.navigation-content a {
  color: var(--gray);
  font-size: 12px;
  text-decoration: none;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s;
}

.navigation-content a:hover {
  color: var(--shade-pink);
  transform: scale(1.2);
  font-weight: 700;
}

.footer-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
}

.footer-contact-name {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.footer-contact-name p {
  color: var(--gray);
  font-size: 12px;
}

.footer-contact-socials {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.contact-social-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  transition: all 0.3s;
  cursor: pointer;
}

.contact-social-container:hover {
  color: var(--shade-pink);
  transform: scale(1.1);
  font-weight: 700;
}

.contact-social-container .contact-link:hover {
  color: var(--shade-pink);
}

.contact-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px;
  background-color: var(--gray);
  border-radius: var(--border-radius);
}

.contact-social-container:hover .contact-icon {
  background-color: var(--shade-pink);
  color: white;
}

.contact-social-container:hover .contact-link {
  color: var(--shade-pink);
}

.contact-link {
  text-decoration: none;
  color: var(--gray);
  font-size: 12px;
  transition: all 0.3s;
}

.social-media-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 80px;
}

.copyright-container {
  padding: 30px 0px;
}

.copyright-container h6 {
  font-size: 14px;
  font-weight: 300;
  color: var(--gray);
}

.copyright-container span {
  color: var(--medium-pink);
}

@media screen and (max-width: 1050px) {
  .footer-logo {
    width: 150px;
  }
  .footer-left {
    width: 20%;
  }
  .footer-right {
    width: 80%;
  }
  .footer-content-container {
    width: 900px;
    padding: 60px;
  }
}

@media screen and (max-width: 910px) {
  .footer-content-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .footer-right {
    width: 100%;
  }
  .footer-right {
    width: 100%;
  }
  .footer-logo {
    width: 100px;
  }
  .footer-content-container {
    width: 800px;
  }
  .navigation-content a {
    color: var(--shade-pink);
  }
  .footer-contact-name p {
    color: var(--shade-pink);
  }
  .contact-icon {
    background-color: var(--shade-pink);
  }
  .contact-link {
    color: var(--shade-pink);
  }
}

@media screen and (max-width: 810px) {
  .footer-content-container {
    width: 700px;
  }
  .footer-right {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .navigation-content {
    flex-direction: row;
    gap: 30px;
  }
}

@media screen and (max-width: 710px) {
  .footer-content-container {
    width: 600px;
  }
  .footer-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
  }
  .footer-contact-name p {
    text-align: center;
  }
  .footer-contact-name {
    padding: 12px 0px;
  }
}

@media screen and (max-width: 610px) {
  .footer-content-container {
    width: 500px;
    padding: 80px 40px 40px 40px;
  }
  .navigation-content {
    gap: 20px;
  }
}

@media screen and (max-width: 510px) {
  .footer-content-container {
    width: 400px;
  }
  .navigation-content {
    width: 300px;
    flex-wrap: wrap;
  }
  .social-media-row {
    gap: 30px;
  }
  .contact-icon {
    padding: 8px;
  }
}

@media screen and (max-width: 410px) {
  .footer-content-container {
    width: 300px;
  }
}

@media screen and (max-width: 350px) {
  .social-media-row {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
  }
  .footer-contact-socials {
    gap: 12px;
  }
  .navigation-content {
    width: 270px;
  }
  .copyright-container {
    padding: 0px 0px 30px 0px;
  }
}
